import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Icon from "../../../../components/UI/Icon";
import delay from "../../../../helpers/delay";
import { ReportSummaryState } from "../../../../store/reportSummary/types";
import { AppState } from "../../../../store/store";
import styles from "./DashboardTools.module.scss";
import OffersContainer from "./Offers/OffersContainer";
import ModalOverdue from "./Overdues/ModalOverdue";
import OverduesContainer from "./Overdues/OverduesContainer";
import ScoreContainer from "./Score/ScoreContainer";
//import SocialSecurityContainer from "./SocialSecurity/SocialSecurityContainer";

type Key = "score" | "products" | "security_social" | "overdues";

interface Tool {
  key: Key;
  label: string;
  icon: string;
  color: string;
  component: React.ReactNode;
};

interface Props {
  reportSummaryState: ReportSummaryState;
}

const DashboardTools = ({ reportSummaryState }: Props) => {
  const ref = useRef<HTMLHRElement>(null);

  const [tool, setTool] = useState<Key>();
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<React.ReactNode>();

  const tools: Tool[] = [
    {
      key: "score",
      label: "Sobre mi puntaje",
      icon: "readiness_score",
      color: "violet",
      component: <ScoreContainer />,
    },
    {
      key: "products",
      label: "Productos para ti",
      icon: "monetization_on",
      color: "green",
      component: <OffersContainer />,
    },
    // {
    //   key: "security_social",
    //   label: "Seguridad social",
    //   icon: "shield_person",
    //   color: "orange",
    //   component: <SocialSecurityContainer />,
    // },
    {
      key: "overdues",
      label: "Novedades con aliados",
      icon: "account_balance",
      color: "red",
      component: <OverduesContainer />,
    },
  ];

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const key = "_eb_overdue_alert_";
      const { payload } = reportSummaryState;
      const { overdues } = payload;
      const hasOverdues = !!overdues && !!overdues.length;
      const opened = sessionStorage.getItem(key);
      if (hasOverdues && !opened) {
        setOpen(true);
        sessionStorage.setItem(key, "true");
      }

    }
  }, [reportSummaryState.status]);

  const selectTool = async (key: Key, component: React.ReactNode) => {
    setTool(key);
    setComponent(component);
    await delay(50);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const showOverdueTool = () => {
    setOpen(false);
    selectTool("overdues", <OverduesContainer />)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tools}>
          {tools.map(({ key, label, icon, color, component }) => (
            <div
              key={key}
              className={`eb-card ${styles.tool} ${key === tool ? styles.active : ""}`}
              onClick={() => selectTool(key, component)}
            >
              <div className={`${styles.shape} ${styles[color]}`}>
                <Icon icon={icon} />
              </div>
              <p className={styles.label}>
                {label}
              </p>
            </div>
          ))}
        </div>
        {tool && <hr ref={ref} className={styles.hr} />}
        {component}
      </div>
      {open && <ModalOverdue onShow={showOverdueTool} />}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(DashboardTools);
