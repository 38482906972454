import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "reactstrap/lib/Button";
import Icon from "../../../../components/UI/Icon";
import { formatDate } from "../../../../helpers/date";
import { ReportSummaryState } from "../../../../store/reportSummary/types";
import { AppState } from "../../../../store/store";
import styles from "./DashboardAlert.module.scss";

interface Props {
  reportSummaryState: ReportSummaryState;
}

const DashboardAlert = ({ reportSummaryState }: Props) => {
  const [date, setDate] = useState("");

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      const { date } = payload;
      setDate(formatDate(date, { in: "DD/MM/YYYY HH:mm:ss" }));
    }
  }, [reportSummaryState.status]);

  const handleClick = () => {
    const element = document.getElementById("dashboard_report");
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <div className="eb-card">
      <div className={styles.content}>
        <Icon
          icon="error"
          className={styles.icon}
        />
        <div className={styles.text}>
          <p className={styles.title}>
            Tienes productos en mora.
          </p>
          <p className={styles.subtitle}>
            Estos retrasos en los pagos pueden afectar tu historial crediticio y reducir tus oportunidades financieras.
          </p>
          <p className={styles.hint}>
            La información se basa en una consulta del {date} y puede estar desactualizada.
          </p>
        </div>
        <Button
          color="primary"
          onClick={handleClick}
          className={styles.btn}
        >
          Ver productos
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(DashboardAlert);
