import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Icon from "../../../../components/UI/Icon";
import { productMetadata } from "../../../../dictionaries/products";
import { reportKeyMap } from "../../../../dictionaries/reportKeyMap";
import isSubscribed from "../../../../helpers/isSubscribed";
import useReportsDetailGet, { Product } from "../../../../services/useReportsDetailGet";
import { ReportSummaryState } from "../../../../store/reportSummary/types";
import { AppState } from "../../../../store/store";
import ProductsType from "../../../../types/report/Products";
import styles from "./ProductReport.module.scss";
import Products from "./Products";
import ProductsFree from "./ProductsFree";

export interface Props {
  product: Product;
  reportSummaryState: ReportSummaryState;
}

const ProductReport = ({ product, reportSummaryState }: Props) => {
  const userIsSubscribed = isSubscribed();
  const [open, setOpen] = useState(false);
  const [overdue, setOverdue] = useState(false);
  const { icon, plural } = productMetadata[product];
  const [products, setProducts] = useState<ProductsType>({});
  const service = useReportsDetailGet({ product, dispatch: userIsSubscribed });


  useEffect(() => {
    if (service && service.status === "loaded") {
      const { payload } = service;
      setProducts(payload.products);
    }
  }, [service]);

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      const key = reportKeyMap[product];
      const products = payload[key] || [];
      setOverdue(products.some(({ overdue }) => overdue));
    }
  }, [reportSummaryState.status])

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        onClick={() => setOpen(!open)}
      >
        <div className={`${styles.shape} ${overdue ? styles.overdue : ""}`}>
          <Icon icon={icon} />
        </div>
        <p className={styles.label}>
          {plural}
        </p>
        <Icon
          icon={open ? "expand_less" : "expand_more"}
          className={styles.icon}
        />
      </div>
      {open && userIsSubscribed && <Products products={products} />}
      {open && !userIsSubscribed && <ProductsFree product={product} overdue={overdue} />}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(ProductReport);